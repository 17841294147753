<template>
  <div v-if="EditItem" class="employmentSumaryContainer">
    <el-dialog :title="EditItem.title1" :visible.sync="dialogshow" :close-on-click-modal="false"
      :modal-append-to-body="false" :destroy-on-close="true" @close="closeDialog" width="800px" custom-class="cus_dialog">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">{{ EditItem.username }} {{ EditItem.title1 }}</span>
      </div>
      <div>
        <el-form :model="EditItem" ref="EditItem" label-width="120px" v-if="EditItem">
          <el-row style="padding-top: 10px;">
            <el-col :span="22">
              <el-form-item label="标题">
                <el-input v-model="EditItem.title" :disabled="disabled" style="width:100%" placeholder="请输入标题"></el-input>
                <!-- <span v-if="disabled">{{ EditItem.title }}</span> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="内容">
            <el-col :span="22">
              <div style="line-height: initial; width: 100%; ">
                <el-input v-model="EditItem.content" :disabled="disabled" type="textarea" :autosize="{ minRows: 10 }"
                  :show-word-limit="true" :maxlength="10000" style="width:100%;"></el-input>
                <!-- <UEditor ref="ue" @ready="editorReady" style="width: 100%; "></UEditor> -->
              </div>
            </el-col>
          </el-form-item>
          <el-form-item label="附件">
            <div v-if="EditItem.attachment && EditItem.attachment.length > 0"
              style="width:100%;display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
              v-for="(img, idx) in EditItem.attachment" :key="idx">

              <div class="flexStart">
                <div class="pointer" @click="openFile(f)"
                  style="color:#2469f3;text-decoration:underline;margin-bottom:5px;">
                  ({{ idx + 1 }}).{{ img.fileName }}</div>
                <div class="imgremove" v-if="!disabled" @click="removeImg(idx)">
                  <i class="el-icon-remove"></i>
                </div>
              </div>
            </div>





            <div style="display:inline-block;vertical-align: top;" v-if="!disabled">
              <el-upload style="display: inline-block; margin-right: 20px" action :http-request="uploadOss"
                :on-success="uploadFiles" :show-file-list="false" name="image">
                <el-button size="small" type="primary" plain style="margin-top:10px;">点击上传</el-button>

              </el-upload>
            </div>
            <div v-if="disabled && (!EditItem.attachment || EditItem.attachment.length == 0)">
              -
            </div>
          </el-form-item>

          <!-- <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="排序号">
                <el-input-number
                  v-model="EditItem.sort"
                  :disabled="disabled"
                  controls-position="right"
                  @change="handleChange"
                  style="width:180px"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row> -->
        </el-form>

      </div>

      <div slot="footer" :span="22" style="text-align: right;padding-left: 10px;"
        v-if="$store.state.teacher.id == EditItem.teacher_id || EditItem.id == 0">
        <el-button size="small" icon="el-icon-edit" type="primary" @click="submitForm()">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UEditor from "../../notify/ueditor.vue";
import util from "../../../utils/util.js";
export default {
  components: { UEditor },
  props: ["id"],
  data() {
    return {
      thisTeacherid: 0,
      dialogshow: true,
      disabled: true,
      editor: null,
      EditItem: {
        id: 0,
        title: "",
        class_name: "",
        teacher_id: 0,
        teacher_name: "",
        teacher_code: "",
        content: "",
        plan_time: "",
        attachment: [],

      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      options: []
    };
  },
  mounted() {
    this.$$parent(this, "EditItem").then(res => {
      if (res.id == 0) {
        res.teacher_id = this.$store.state.teacher.id;
        res.teacher_name = this.$store.state.teacher.username;
        res.teacher_code = this.$store.state.teacher.loginname;
        res.plan_time = util.formatDate(new Date());
        this.disabled = false;
        this.initTeacherInfo(0);
      } else {
        console.log(161, res)
        res.attachment = res.attachment ? JSON.parse(res.attachment) : [];
        if (this.$store.state.teacher.id == res.teacher_id) {
          this.disabled = false;
          this.initTeacherInfo(res.class_id);
        } else {
          //修改不是自己的
          this.options = [{ id: res.class_id, name: res.class_name }];
          this.EditItem.class_id = this.options[0].id;
        }
        setTimeout(() => {
          // this.editor.setContent(this.EditItem.content);
        }, 500);
      }
      this.EditItem = res;
    });
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    initTeacherInfo(class_id) {
      this.$http.post("/api/get_tch_cls_data").then(res => {
        this.options = [].concat(res.data.bj_list).concat(res.data.sxxz_list);
        if (class_id > 0) {
          this.EditItem.class_id = class_id;
        } else {
          this.EditItem.class_id = this.options[0].id;
        }
      });
    },
    closeDialog() {
      this.$$parent(this, "dialogshow", false);
    },
    uploadFiles(e) {
      this.EditItem.attachment.push(e);
    },
    removeImg(idx) {
      this.EditItem.attachment.splice(idx, 1);
    },
    openFile(f) {
      let path = f.src
      const fileExtension = path.split('.').pop().toLowerCase()
      if (fileExtension === 'ppt' || fileExtension === 'pptx') {
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(f.src))
      } else {
        window.open('http://www.pfile.com.cn/api/profile/onlinePreview?url=' + encodeURIComponent(f.src));

      }
    },
    submitForm(st) {
      let data = JSON.parse(JSON.stringify(this.EditItem));
      data.attachment = JSON.stringify(data.attachment);

      console.log(216, data)
      if (!data.content || data.content.length < 100) {
        this.$message.error("内容必须大于100字!");
        return false;
      }
      if (!data.title.trim()) {
        this.$message.error("标题不能为空!");
        return false;
      }
      data.type = 1
      this.$http.post("/api/employment_summary_edit", data).then(res => {
        this.$message({
          type: "success",
          message: "提交成功"
        });
        this.$$parent(this, "getList");
        this.$$parent(this, "dialogshow", false);
      });
    },
    editorReady(editorInstance) {
      editorInstance.setContent(this.EditItem.content);
      editorInstance.addListener("contentChange", () => {
        this.EditItem.content = editorInstance.getContent();
      });
      this.editor = editorInstance;
    }
  }
};
</script>
<style>
.employmentSumaryContainer .is-disabled .el-input__inner,
.el-textarea__inner {
  color: #606066 !important;
}
</style>
<style scoped>
.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  /* position: absolute; 
   top: -5px;
  right: -5px;*/
  color: #ccc;
  margin-left: 10px;
}

.imgremove:hover {
  color: orange;
}
</style>
